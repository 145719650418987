import * as React from "react"
import Seo from "../components/layout/Seo"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";

import DownArrow from '../images/svgs/DownArrow.svg'
import PygmalionLogoHomepage from '../images/svgs/PygmalionLogoHomepage.js'
import { SuperTitle, SectionTitle } from '../components/components/PageElements'

import { indexHolder } from "./Index.module.scss"
import { Link } from "gatsby";
import { motion, MotionConfig } from "framer-motion";
import { InView } from 'react-intersection-observer'

import { sectionVar, fadeNorth, fadeEast, fadeWest} from '../data/transitions'


const beyondParentVariants = {
  pre: {
    opacity:0
  },
  vis: {
    opacity:1,
    transition: {
      staggerChildren: 0.3,
      type:'tween',
      duration:1
    }
  },
  post: {
    opacity:1
  }
}

const beyondChildVariants = {
  pre: {
    opacity:0,
    y:'1.5rem'
  },
  vis: {
    opacity:1,
    y:0,
    transition: {
      type:'tween',
      duration:1
    }
  },
  post: {
    opacity:0,
    y:'-1.5rem'
  }
}

const arrowBounce = {
  animate: {
    y: [10,0,10,0,10],
    transition: {
      repeat: Infinity,
      repeatDelay: 3,
      bounce: 0.7,
      type:'spring',
      mass:2
    }
  }
}

const IndexPage = () => {
  return (
    <MotionConfig transition={{duration:0.75, type:"tween"}}>
    <article className={indexHolder} >
      <Seo title="Pygmalion Capital Ltd" description="Dedicated Hotel Investing" />
      <section className="heroSection">
        <motion.div className="heroTitles" variants={beyondParentVariants} initial="pre" animate="vis" exit="post">
          <h5>
            <motion.span variants={beyondChildVariants}>London - </motion.span>
            <motion.span variants={beyondChildVariants}>Barcelona - </motion.span>
            <motion.span variants={beyondChildVariants}>Beyond</motion.span>
          </h5>
          <h1>Pygmalion Capital</h1>
          <PygmalionLogoHomepage />
          <h2>
            <motion.span variants={beyondChildVariants}>Dedicated</motion.span>
            <motion.span variants={beyondChildVariants}>Hotel</motion.span>
            <motion.span variants={beyondChildVariants}>Investing</motion.span>
          </h2>
        </motion.div>
        <motion.div initial={{opacity:0}} animate={{opacity:1, transition: { delay: 2 }}} className="heroC2A">
          <AnchorLink to="/#about">
            <div className="diveIn">Dive in</div>
            <motion.div variants={arrowBounce} animate="animate"><DownArrow /></motion.div>
          </AnchorLink>
        </motion.div>
        <div className="heroImage">
          <StaticImage
            src="../images/chair-and-pool.jpg"
            alt="Pygmalion Capital LTD - Dedicated to Hotel Investing"
            quality={80}
            width={2000}
            objectPosition="70% 50%"
          />
        </div>
      </section>

      <InView threshold={0.25} triggerOnce={true}>
        {({ref, inView, entry}) => {
          

        return (
        <section ref={ref} className="indexAboutSection" id="about">
          <div className="inner">
            <SuperTitle inView={inView} title='About Pygmalion Capital' />
            <SectionTitle inView={inView}><strong>Pygmalion Capital Advisers LLP</strong> is an investment firm exclusively focused on hotel investing</SectionTitle>
          </div>
        </section> )}}
      </InView>

      <InView threshold={0.25} triggerOnce={true}>
      {({ref, inView}) => (
      <section ref={ref} className="indexMoreAboutSection" id="about">
        <motion.div variants={sectionVar} initial="i" animate={inView ? 'v' : 'i'} exit="o" className="lrHolder">
          <motion.div className="left" variants={fadeEast} >
            <StaticImage
              src="../images/pages/index/moreAbout-restaurant-sketch.jpg"
              alt="Pygmalion Capital LTD - Dedicated to Hotel Investing"
              quality={80}
              width={1800}
              backgroundColor="transparent"
              placeholder="tracedSVG"
            />
          </motion.div>
          <motion.div className="right" variants={fadeWest} >
            <div className="inner">
            <p>At <strong>Pygmalion</strong>, we offer opportunistic investing expertise, extensive local market relationships and active asset management experience within the hotel sector.</p>

            <p>The <strong>London</strong> and <strong>Barcelona</strong>-based team is made up of experienced professionals who <strong>source</strong>, <strong>negotiate</strong> and <strong>execute</strong> hotel investment transactions <strong>across Europe</strong>.</p>

            <p>We manage carefully selected <strong>partnerships with operators</strong> able to maximize both <strong>top-line and bottom-line</strong> performance.</p>
              <Link to="about" title="More about Pygmalion" className="softButton">More about Pygmalion</Link>
            </div>
          </motion.div>
        </motion.div>
      </section>
      )}
      </InView>

      <InView threshold={0.25} triggerOnce={true}>
      {({ref, inView}) => (
      <section ref={ref} className="indexMeetSection">
        <motion.div variants={sectionVar} initial="i" animate={inView ? 'v' : 'i'} exit="o" className="lrHolder">
          <motion.div className="left" variants={fadeEast}>
            <StaticImage
              src="../images/pages/index/bell.jpg"
              alt="Pygmalion Capital - Meet The Team"
              placeholder="tracedSVG"
              quality={80}
              width={500}
            />
          </motion.div>
          <motion.div variants={fadeWest} className="right">
            <SuperTitle title="The Team" inView={inView} />
            <SectionTitle>At your service</SectionTitle>
            <p>The London and Barcelona‐based team comprises experienced professionals combining years of expertise sourcing and structuring hotel investment transactions across major European jurisdictions.</p>
            <Link to="our-team" title="Meet the team" className="softButton">Meet the team</Link>
          </motion.div>
        </motion.div>
      </section>
      )}
      </InView>

      <InView threshold={0.25} triggerOnce={true}>
      {({ref, inView}) => (
      <section ref={ref} className="indexCaseStudiesSection">
        <motion.div variants={sectionVar} initial="i" animate={inView ? 'v' : 'i'} exit="o" className="lrHolder">
          <motion.div variants={fadeNorth} className="left">
            <StaticImage
              src="../images/pages/index/caseStudies.jpg"
              alt="Pygmalion Capital - Case Studies"
              quality={80}
              width={1800}
              className="roundedCorners"
            />
          </motion.div>
          <div className="right">
            <SuperTitle inView={inView} title='Case Studies' />
            <SectionTitle>
              <motion.span variants={fadeNorth}>Our </motion.span>
              <motion.span variants={fadeNorth}> investments</motion.span>
            </SectionTitle>
            <motion.p variants={fadeNorth}> Pygmalion’s investment and asset management teams work together to identify opportunities and implement strategies targeted to each asset.</motion.p>
            <motion.p variants={fadeNorth}>Details for a selection of our investments are included here.</motion.p>
            <motion.div variants={fadeNorth} className="buttonHolder"><Link to="case-studies" title="Case Studies" className="softButton">Case studies</Link></motion.div>
          </div>
        </motion.div>
      </section>
      )}
      </InView>



      <InView threshold={0.25} triggerOnce={true}>
      {({ref, inView}) => (
      <section ref={ref} className="indexHiringSection">
        <motion.div variants={sectionVar} initial="i" animate={inView ? 'v' : 'i'} exit="o" className="lrHolder">
        <motion.div className="left" variants={fadeEast}>
          <StaticImage
            src="../images/pages/index/handshake.jpg"
            alt="Pygmalion Capital - We're Hiring"
            placeholder="tracedSVG"
            quality={80}
            width={600}
          />
        </motion.div>
        <motion.div variants={fadeWest} className="right">
          <SuperTitle title="Career Opportunities" inView={inView} />
          <SectionTitle>We're hiring</SectionTitle>
          <p>Pygmalion Capital is currently looking for candidates to join our Investment and Asset Management team in London.</p>
          <p>A strong understanding of the hotel investment sector is required and preferably previous experience within a real estate investment group or hotel group. </p>
          <Link to="/contact?type=Employment%20vacancy%20enquiry#contact-form" title="Career Opportunities" className="softButton">Contact us for more information</Link>
          </motion.div>
        </motion.div>
      </section>
      )}
      </InView>

    </article>
    </MotionConfig>
  )
}

export default IndexPage
