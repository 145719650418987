import React from 'react'
import { motion } from 'framer-motion'

export default function PygmalionLogoHomepage() {

  const svgVar = {
    pre: {
    },
    visible: {
      transition: {
        staggerChildren: 0.1
      }
    },
    post: {
      transition: {
        staggerChildren: 0.02
      }
    }
  }

  const pathVar = {
    pre: {
      pathLength:0,
      y:'3rem',
      fill: 'rgba(58,57,57,0)'
    },
    visible: {
      pathLength: 1,
      y:0,
      fill: 'rgba(58,57,57,1)',
      transition: {
        type: 'tween',
        duration:1
      }
    },
    post: {
      pathLength:0,
      y:'-3rem',
      fill: 'rgba(58,57,57,0)',
      transition: {
        type: 'tween',
        duration:0.2
      }
    }
  }

  const ohmVar = {
    pre: {
      pathLength:0,
      y:20,
      fill: 'rgba(30,61,124,0)'
    },
    visible: {
      pathLength: 1,
      y:0,
      fill: 'rgba(30,61,124,1)',
      transition: {
        type: 'tween',
        duration:2
      }
    },
    post: {
      pathLength:0,
      y:-20,
      fill: 'rgba(30,61,124,0)',
      transition: {
        type: 'tween',
        duration:0.5
      }
    }
  }

  return (
<motion.svg width="100%"
height="100%"
viewBox="0 0 1750 655"
version="1.1"
xmlns="http://www.w3.org/2000/svg"
variants={svgVar}
initial="pre"
animate="visible"
exit="post">

<motion.path variants={pathVar}   d="M1.447,11.629l1746.52,-0" fill="none" fillRule="nonzero" stroke="#3a3939" strokeWidth="8px"/>
<motion.path variants={pathVar}   d="M292.952,642.629l1162.63,0" fill="none" fillRule="nonzero" stroke="#3a3939" strokeWidth="8px"/>
<motion.path variants={pathVar}   d="M43.181,189.658c4.709,-0.193 6.307,-0.53 11.324,-1.594c5.013,-1.064 9.611,-3 13.794,-5.814c4.174,-2.811 7.597,-6.685 10.256,-11.628c2.658,-4.939 3.989,-11.283 3.989,-19.037c0,-6.533 -0.912,-11.854 -2.736,-15.959c-1.82,-4.104 -4.368,-7.293 -7.635,-9.577c-3.27,-2.276 -7.26,-3.834 -11.969,-4.672c-4.712,-0.834 -10.029,-1.253 -15.959,-1.253l-20.519,-0l-0,89.824c-0,10.033 0.189,19.915 0.571,29.637c0.378,9.729 0.797,19.307 1.253,28.725c-3.5,-0.305 -7.219,-0.452 -11.172,-0.452c-3.952,-0 -7.675,0.147 -11.168,0.452c0.452,-9.418 0.872,-18.996 1.254,-28.725c0.374,-9.722 0.567,-19.604 0.567,-29.637l-0,-39.211c-0,-10.03 -0.193,-19.871 -0.567,-29.527c-0.382,-9.647 -0.802,-19.492 -1.254,-29.522c3.649,0.308 7.331,0.571 11.057,0.797c3.723,0.23 7.409,0.345 11.057,0.345c1.825,0 4.023,-0.078 6.611,-0.23c2.581,-0.148 5.202,-0.263 7.865,-0.341c2.658,-0.078 5.206,-0.189 7.638,-0.341c2.429,-0.152 4.479,-0.23 6.155,-0.23c2.277,0 5.087,0.078 8.432,0.23c3.344,0.152 6.878,0.608 10.605,1.369c3.722,0.76 7.408,1.898 11.057,3.418c3.648,1.52 6.952,3.727 9.914,6.611c2.967,2.889 5.362,6.496 7.182,10.831c1.825,4.331 2.737,9.689 2.737,16.07c0,10.034 -2.165,18.165 -6.5,24.394c-4.331,6.234 -9.652,11.098 -15.955,14.591c-3.435,1.902 -6.985,3.472 -10.642,4.696c-1.228,0.415 -4.335,1.405 -9.421,2.371c-4.553,0.863 -7.66,1.751 -15.536,1.824c-5.646,-0.197 -7.162,-8.234 -2.285,-8.435Z" fill="none" fillRule="nonzero" stroke="#3a3939" strokeWidth="4.17px"/>
<motion.path variants={pathVar}   d="M225.491,204.248c-1.524,-2.58 -3.759,-6.344 -6.726,-11.283c-2.962,-4.938 -6.233,-10.411 -9.803,-16.414c-3.575,-6.004 -7.335,-12.311 -11.283,-18.922c-3.957,-6.615 -7.717,-12.918 -11.287,-18.925c-3.575,-6.003 -6.805,-11.398 -9.689,-16.185c-2.889,-4.786 -5.095,-8.398 -6.611,-10.831c2.128,0.308 4.22,0.571 6.27,0.797c2.05,0.23 4.138,0.345 6.266,0.345c2.128,0 4.219,-0.115 6.274,-0.345c2.05,-0.226 4.138,-0.489 6.266,-0.797c6.841,13.222 13.794,26.219 20.861,38.985c7.067,12.766 14.553,26.067 22.459,39.897c3.492,-5.473 7.141,-11.472 10.942,-18.009c3.796,-6.537 7.597,-13.222 11.397,-20.063c3.797,-6.842 7.446,-13.716 10.943,-20.631c3.496,-6.915 6.762,-13.642 9.803,-20.179c3.797,0.765 6.763,1.142 8.892,1.142c2.128,0 5.09,-0.377 8.891,-1.142c-7.293,11.098 -13.83,21.128 -19.607,30.094c-5.777,8.969 -10.868,17.101 -15.273,24.394c-4.408,7.297 -8.287,13.871 -11.628,19.718c-3.344,5.855 -6.233,11.139 -8.661,15.848c-0,5.625 0.033,11.628 0.111,18.009c0.078,6.385 0.189,12.655 0.345,18.811c0.148,6.155 0.341,11.853 0.567,17.096c0.23,5.243 0.493,9.459 0.801,12.652c-3.501,-0.305 -7.224,-0.452 -11.176,-0.452c-3.949,-0 -7.675,0.147 -11.168,0.452c0.912,-7.897 1.442,-17.475 1.594,-28.725c0.152,-11.246 0.23,-23.026 0.23,-35.337Z" fill="none" fillRule="nonzero" stroke="#3a3939" strokeWidth="4.17px"/>
<motion.path variants={pathVar}   d="M504.425,131.266c-3.421,-2.736 -7.221,-5.127 -11.4,-7.182c-4.183,-2.05 -8.817,-3.722 -13.904,-5.013c-5.096,-1.29 -10.525,-1.943 -16.304,-1.943c-9.425,-0 -18.2,1.45 -26.329,4.335c-8.13,2.888 -15.2,7.334 -21.205,13.337c-6.003,6.007 -10.716,13.605 -14.135,22.8c-3.418,9.196 -5.132,20.101 -5.132,32.715c0,11.094 1.521,21.128 4.561,30.093c3.041,8.97 7.487,16.526 13.338,22.681c5.852,6.155 12.998,10.909 21.431,14.249c8.437,3.345 17.971,5.017 28.612,5.017c3.192,0 6.534,-0.147 10.034,-0.456c3.495,-0.304 6.87,-0.756 10.141,-1.368c3.267,-0.608 6.309,-1.368 9.121,-2.28c2.813,-0.913 5.209,-1.973 7.179,-3.193c0.155,-4.557 0.263,-9.118 0.346,-13.678c0.075,-4.557 0.113,-9.196 0.113,-13.905c-0,-5.321 -0.113,-10.638 -0.342,-15.958c-0.225,-5.317 -0.492,-10.408 -0.796,-15.277c3.796,0.764 7.442,1.142 10.942,1.142c1.671,0 3.341,-0.074 5.017,-0.23c1.67,-0.148 3.266,-0.456 4.787,-0.912c-0.304,3.496 -0.538,9.006 -0.688,16.53c-0.15,7.523 -0.225,17.438 -0.225,29.752c0,3.041 0.038,6.118 0.113,9.233c0.075,3.114 0.112,6.192 0.112,9.232c-3.187,1.221 -7.179,2.474 -11.966,3.76c-4.788,1.294 -9.879,2.358 -15.275,3.192c-5.396,0.834 -10.792,1.598 -16.184,2.281c-5.4,0.682 -10.304,1.027 -14.708,1.027c-13.529,0 -25.725,-1.639 -36.587,-4.902c-10.873,-3.27 -20.106,-8.32 -27.707,-15.162c-7.602,-6.837 -13.449,-15.346 -17.549,-25.532c-4.109,-10.182 -6.159,-21.962 -6.159,-35.336c-0,-12.918 2.128,-24.431 6.385,-34.539c4.253,-10.108 10.26,-18.659 18.009,-25.652c7.749,-6.985 17.134,-12.31 28.158,-15.954c11.017,-3.649 23.217,-5.473 36.588,-5.473c7.754,-0 14.629,0.418 20.637,1.253c6,0.834 11.317,1.939 15.958,3.307c4.634,1.368 8.696,2.815 12.196,4.331c3.496,1.52 6.609,2.962 9.346,4.331c-1.221,2.128 -2.317,4.865 -3.308,8.205c-0.988,3.345 -1.629,6.463 -1.934,9.348l-2.737,-0c-2.279,-2.737 -5.129,-5.473 -8.55,-8.206Z" fill="none" fillRule="nonzero" stroke="#3a3939" strokeWidth="4.17px"/>
<motion.path variants={pathVar}   d="M661.729,171.62c10.717,20.367 21.921,41.265 33.629,62.693c1.521,-2.885 3.721,-7.031 6.613,-12.426c2.887,-5.391 6.225,-11.587 10.033,-18.576c3.796,-6.993 7.863,-14.553 12.192,-22.685c4.333,-8.131 8.737,-16.378 13.225,-24.739c4.483,-8.358 8.929,-16.563 13.337,-24.621c4.409,-8.057 8.509,-15.576 12.309,-22.569l2.966,-0c1.671,14.59 3.225,28.688 4.671,42.288c1.446,13.604 2.929,26.983 4.446,40.127c1.517,13.144 3.042,26.145 4.562,38.985c1.517,12.844 3.192,25.573 5.013,38.183c-1.821,-0.304 -3.608,-0.452 -5.354,-0.452l-10.717,0c-1.75,0 -3.533,0.148 -5.358,0.452c-1.675,-20.211 -3.342,-39.815 -5.017,-58.814c-1.671,-18.999 -3.725,-38.381 -6.154,-58.136l-0.454,0c-10.488,19.147 -20.788,38.188 -30.892,57.109c-10.112,18.921 -19.721,38.722 -28.842,59.389l-2.05,0c-9.12,-19.151 -18.662,-38.335 -28.612,-57.565c-9.954,-19.229 -19.95,-38.873 -29.979,-58.933l-0.454,0c-1.067,9.426 -2.25,19.645 -3.534,30.66c-1.296,11.024 -2.475,21.888 -3.533,32.604c-1.071,10.716 -1.979,20.824 -2.738,30.319c-0.762,9.504 -1.137,17.295 -1.137,23.367c-2.438,-0.304 -5.096,-0.452 -7.979,-0.452c-2.896,0 -5.554,0.148 -7.984,0.452c1.975,-12.31 4.025,-25.302 6.159,-38.98c2.125,-13.679 4.179,-27.435 6.154,-41.266c1.971,-13.83 3.796,-27.471 5.475,-40.924c1.667,-13.452 3.108,-26.251 4.329,-38.413l3.875,-0c10.483,21.583 21.088,42.559 31.8,62.923Z" fill="none" fillRule="nonzero" stroke="#3a3939" strokeWidth="4.17px"/>
<motion.path variants={pathVar}   d="M898.488,197.382c3.954,0 8.058,0.078 12.312,0.226c4.254,0.152 8.358,0.226 12.313,0.226c4.25,0 8.508,-0.033 12.77,-0.111c4.246,-0.074 8.509,-0.189 12.767,-0.341l-25.313,-57.91l-24.85,57.91Zm101,70.898c-2.133,-0.304 -4.145,-0.452 -6.045,-0.452l-12.084,0c-1.9,0 -3.916,0.148 -6.041,0.452c-1.059,-3.492 -2.505,-7.708 -4.33,-12.651c-1.82,-4.934 -3.841,-10.108 -6.041,-15.503c-2.208,-5.394 -4.442,-10.863 -6.721,-16.414c-2.283,-5.547 -4.413,-10.827 -6.383,-15.844c-4.871,-0.152 -9.809,-0.263 -14.825,-0.341c-5.013,-0.074 -10.025,-0.115 -15.042,-0.115c-4.712,-0 -9.35,0.041 -13.904,0.115c-4.567,0.078 -9.204,0.189 -13.913,0.341c-4.866,11.854 -9.27,23.141 -13.22,33.857c-3.955,10.716 -6.921,19.57 -8.896,26.555c-2.584,-0.304 -5.396,-0.452 -8.434,-0.452c-3.041,0 -5.854,0.148 -8.437,0.452c12.317,-26.592 24.317,-53.037 36.025,-79.337c11.7,-26.289 23.479,-53.038 35.337,-80.246l4.334,-0c3.646,8.817 7.596,18.35 11.85,28.609c4.254,10.26 8.587,20.635 12.996,31.121c4.408,10.486 8.816,20.902 13.225,31.231c4.404,10.338 8.545,19.949 12.42,28.84c3.884,8.892 7.375,16.838 10.488,23.827c3.117,6.99 5.662,12.311 7.642,15.955Z" fill="none" fillRule="nonzero" stroke="#3a3939" strokeWidth="4.17px"/>
<motion.path variants={ohmVar}   d="M1328.82,312.781l-0,-54.521l5.35,0c1.108,4.237 2.279,7.693 3.512,10.371c1.225,2.675 2.679,4.738 4.346,6.184c1.675,1.455 3.796,2.4 6.358,2.843c2.559,0.452 5.846,0.67 9.867,0.67l49.5,0l0,-4.684c-17.396,-4.901 -30.996,-11.254 -40.804,-19.061c-9.817,-8.033 -17.558,-18.51 -23.246,-31.445c-5.687,-12.93 -8.529,-26.309 -8.529,-40.135c-0,-16.497 4.346,-32.444 13.042,-47.831c8.916,-15.388 21.795,-27.259 38.633,-35.62c16.833,-8.366 36.4,-12.545 58.704,-12.545c22.742,0 42.25,4.13 58.529,12.376c17.171,8.477 29.992,20.401 38.467,35.789c8.912,14.943 13.379,30.886 13.379,47.831c0,13.16 -2.904,26.539 -8.7,40.135c-5.575,13.161 -13.267,23.585 -23.079,31.277c-9.808,7.695 -23.3,14.105 -40.471,19.229l0,4.684l49.504,0c4.013,0 7.304,-0.218 9.871,-0.67c2.558,-0.443 4.683,-1.388 6.35,-2.843c1.675,-1.446 3.067,-3.509 4.188,-6.184c1.108,-2.678 2.22,-6.134 3.345,-10.371l5.684,0l-0,54.521l-99.004,-0l5.35,-47.494c13.154,-4.906 23.183,-12.043 30.104,-21.407c11.371,-15.832 17.058,-36.236 17.058,-61.21c0,-28.1 -6.246,-49.171 -18.733,-63.219c-12.492,-14.048 -29.434,-21.074 -50.846,-21.074c-22.292,-0 -39.021,6.245 -50.167,18.732c-14.05,15.61 -21.075,37.354 -21.075,65.224c0,16.057 2.179,30.106 6.525,42.144c4.346,12.044 10.538,21.519 18.563,28.43c4.904,4.91 12.262,9.031 22.079,12.38l5.017,47.494l-98.671,-0Z" fill="#1e3d7c" fillRule="nonzero" stroke="#1E3D7C" strokeWidth="4.17px"/>
<motion.path variants={pathVar}   d="M1080.76,141.21c-0.384,-9.647 -0.796,-19.492 -1.259,-29.522c1.979,0.308 3.884,0.571 5.704,0.797c1.825,0.23 3.65,0.345 5.471,0.345c1.821,0 3.65,-0.115 5.471,-0.345c1.821,-0.226 3.721,-0.489 5.704,-0.797c-0.462,10.03 -0.879,19.875 -1.258,29.522c-0.383,9.656 -0.571,19.497 -0.571,29.527c0,19.151 0.075,35.759 0.229,49.811c0.15,14.061 0.3,26.642 0.454,37.732c10.792,0 21.125,-0.115 31.005,-0.341c9.883,-0.23 20.52,-1.097 31.92,-2.621c-0.762,2.736 -1.141,5.012 -1.141,6.837c-0,1.676 0.379,3.726 1.141,6.155c-6.991,-0.305 -13.987,-0.452 -20.975,-0.452l-42.062,-0c-6.763,-0 -13.792,0.147 -21.092,0.452c0.463,-9.418 0.875,-18.996 1.258,-28.725c0.38,-9.722 0.571,-19.604 0.571,-29.637l0,-39.211c0,-10.03 -0.191,-19.871 -0.571,-29.527Z" fill="none" fillRule="nonzero" stroke="#3a3939" strokeWidth="4.17px"/>
<motion.path variants={pathVar}   d="M1250.38,141.21c-0.383,-9.647 -0.796,-19.492 -1.258,-29.522c1.979,0.308 3.879,0.571 5.704,0.797c1.821,0.23 3.65,0.345 5.471,0.345c1.825,0 3.65,-0.115 5.471,-0.345c1.825,-0.226 3.721,-0.489 5.7,-0.797c-0.455,10.03 -0.875,19.875 -1.255,29.522c-0.383,9.656 -0.566,19.497 -0.566,29.527l-0,39.21c-0,10.034 0.183,19.916 0.567,29.638c0.379,9.729 0.8,19.307 1.254,28.725c-3.496,-0.305 -7.225,-0.453 -11.171,-0.453c-3.954,0 -7.679,0.148 -11.175,0.453c0.462,-9.418 0.875,-18.996 1.258,-28.725c0.379,-9.722 0.567,-19.604 0.567,-29.638l-0,-39.21c-0,-10.03 -0.188,-19.871 -0.567,-29.527Z" fill="none" fillRule="nonzero" stroke="#3a3939" strokeWidth="4.17px"/>
<motion.path variants={pathVar}   d="M1683.82,209.121c-19.755,-21.202 -39.513,-42.289 -59.271,-63.264c-0.154,4.257 -0.304,9.956 -0.458,17.097c-0.15,7.145 -0.226,16.871 -0.226,29.181c0.001,3.192 0.038,7.15 0.117,11.858c0.071,4.713 0.146,9.652 0.225,14.817c0.075,5.169 0.188,10.416 0.342,15.732c0.15,5.321 0.304,10.26 0.458,14.821c0.15,4.557 0.3,8.547 0.454,11.965c0.15,3.419 0.3,5.74 0.455,6.952c-2.438,-0.304 -5.096,-0.452 -7.98,-0.452c-3.037,0 -5.85,0.148 -8.437,0.452c0.304,-1.516 0.646,-4.33 1.029,-8.431c0.379,-4.105 0.721,-9.007 1.025,-14.706c0.3,-5.698 0.646,-11.891 1.025,-18.58c0.379,-6.689 0.688,-13.374 0.913,-20.063c0.229,-6.685 0.42,-13.223 0.57,-19.608c0.155,-6.381 0.225,-12.084 0.225,-17.097c0,-14.59 -0.037,-27.13 -0.108,-37.616c-0.079,-10.49 -0.267,-18.314 -0.571,-23.482l3.646,-0c9.271,10.029 19.183,20.786 29.754,32.258c10.559,11.476 21.05,22.8 31.459,33.968c10.412,11.172 20.291,21.736 29.633,31.692c9.354,9.955 17.521,18.428 24.516,25.417l0,-31.005c0,-15.959 -0.116,-31.495 -0.345,-46.62c-0.229,-15.124 -0.725,-29.374 -1.479,-42.748c3.037,0.764 5.62,1.142 7.75,1.142c1.829,0 4.254,-0.378 7.295,-1.142c-0.304,3.345 -0.65,8.361 -1.025,15.046c-0.383,6.69 -0.762,13.835 -1.146,21.432c-0.374,7.602 -0.716,15.047 -1.024,22.34c-0.305,7.298 -0.459,13.148 -0.459,17.558l0,13.678c0,5.169 0.042,10.564 0.117,16.184c0.079,5.625 0.154,11.288 0.229,16.987c0.079,5.698 0.15,11.019 0.229,15.958c0.071,4.943 0.117,9.233 0.117,12.882l-0,7.523l-3.192,0c-17.483,-20.212 -36.1,-40.924 -55.862,-62.126Z" fill="none" fillRule="nonzero" stroke="#3a3939" strokeWidth="4.17px"/>
<motion.path variants={pathVar}   d="M411.341,539.82c-2.962,1.445 -6.533,2.733 -10.712,3.875c-4.182,1.141 -8.858,2.166 -14.023,3.075c-5.169,0.916 -10.564,1.366 -16.185,1.366c-12.01,0 -23.104,-1.708 -33.286,-5.129c-10.186,-3.417 -18.962,-8.621 -26.333,-15.604c-7.376,-7 -13.148,-15.55 -17.323,-25.658c-4.187,-10.105 -6.275,-21.621 -6.275,-34.534c0,-12.921 2.088,-24.433 6.275,-34.546c4.175,-10.104 10.025,-18.652 17.553,-25.641c7.523,-6.989 16.526,-12.314 27.016,-15.959c10.485,-3.649 22.035,-5.473 34.653,-5.473c10.03,0 19.185,1.023 27.468,3.078c8.284,2.05 15.618,4.523 22.003,7.408c-1.22,2.132 -2.206,5.017 -2.962,8.665c-0.764,3.649 -1.369,6.685 -1.825,9.118l-1.368,0.452c-1.52,-1.824 -3.648,-3.871 -6.385,-6.151c-2.732,-2.28 -5.925,-4.483 -9.573,-6.611c-3.649,-2.129 -7.754,-3.912 -12.311,-5.362c-4.561,-1.439 -9.577,-2.162 -15.047,-2.162c-8.969,0 -17.294,1.447 -24.965,4.331c-7.679,2.889 -14.36,7.334 -20.063,13.333c-5.699,6.008 -10.145,13.604 -13.338,22.799c-3.192,9.196 -4.786,20.1 -4.786,32.721c-0,12.613 1.594,23.517 4.786,32.709c3.193,9.2 7.639,16.8 13.338,22.795c5.703,6.013 12.384,10.459 20.063,13.342c7.671,2.892 15.996,4.333 24.965,4.333c5.47,0 10.519,-0.683 15.158,-2.054c4.635,-1.366 8.892,-3 12.77,-4.9c3.875,-1.9 7.367,-3.954 10.486,-6.15c3.115,-2.208 5.736,-4.146 7.865,-5.816l0.912,0.904l-2.051,13.454c-1.368,1.225 -3.533,2.55 -6.5,3.992Z" fill="none" fillRule="nonzero" stroke="#3a3939" strokeWidth="4.17px"/>
<motion.path variants={pathVar}   d="M522.258,474.274c3.95,-0 8.058,0.079 12.308,0.225c4.254,0.158 8.358,0.229 12.313,0.229c4.254,-0 8.512,-0.033 12.766,-0.113c4.254,-0.075 8.513,-0.187 12.767,-0.341l-25.304,-57.909l-24.85,57.909Zm100.996,70.9c-2.13,-0.3 -4.142,-0.454 -6.042,-0.454l-12.083,-0c-1.905,-0 -3.917,0.154 -6.042,0.454c-1.063,-3.492 -2.504,-7.709 -4.329,-12.654c-1.829,-4.93 -3.842,-10.105 -6.046,-15.496c-2.204,-5.396 -4.446,-10.867 -6.729,-16.417c-2.275,-5.546 -4.404,-10.829 -6.375,-15.85c-4.867,-0.146 -9.804,-0.258 -14.821,-0.337c-5.017,-0.075 -10.029,-0.117 -15.046,-0.117c-4.717,-0 -9.35,0.042 -13.912,0.117c-4.555,0.079 -9.192,0.191 -13.905,0.337c-4.866,11.854 -9.27,23.142 -13.22,33.858c-3.959,10.717 -6.921,19.58 -8.892,26.559c-2.588,-0.3 -5.396,-0.454 -8.433,-0.454c-3.046,-0 -5.855,0.154 -8.438,0.454c12.313,-26.592 24.317,-53.034 36.017,-79.338c11.704,-26.291 23.483,-53.039 35.341,-80.244l4.33,0c3.65,8.818 7.6,18.35 11.854,28.61c4.258,10.255 8.587,20.63 12.996,31.122c4.408,10.483 8.816,20.896 13.225,31.229c4.404,10.337 8.55,19.95 12.425,28.842c3.875,8.891 7.37,16.833 10.487,23.829c3.113,6.987 5.658,12.308 7.638,15.95Z" fill="none" fillRule="nonzero" stroke="#3a3939" strokeWidth="4.17px"/>
<motion.path variants={pathVar}   d="M743.249,466.549c4.705,-0.192 6.305,-0.529 11.317,-1.596c5.017,-1.058 9.613,-3 13.796,-5.813c4.179,-2.812 7.596,-6.687 10.258,-11.629c2.659,-4.933 3.992,-11.283 3.992,-19.037c-0,-6.529 -0.913,-11.854 -2.738,-15.957c-1.825,-4.101 -4.37,-7.293 -7.637,-9.574c-3.267,-2.276 -7.263,-3.833 -11.971,-4.672c-4.712,-0.834 -10.029,-1.253 -15.958,-1.253l-20.517,0l0,89.822c0,10.03 0.188,19.913 0.571,29.638c0.375,9.725 0.8,19.304 1.254,28.721c-3.5,-0.3 -7.221,-0.45 -11.171,-0.45c-3.954,-0 -7.679,0.15 -11.171,0.45c0.455,-9.417 0.871,-18.996 1.25,-28.721c0.38,-9.725 0.571,-19.608 0.571,-29.638l0,-39.212c0,-10.029 -0.191,-19.871 -0.571,-29.525c-0.379,-9.646 -0.795,-19.491 -1.25,-29.52c3.646,0.304 7.33,0.567 11.055,0.797c3.725,0.23 7.412,0.341 11.058,0.341c1.825,-0 4.025,-0.074 6.612,-0.226c2.584,-0.148 5.205,-0.267 7.863,-0.345c2.658,-0.074 5.204,-0.185 7.637,-0.341c2.43,-0.148 4.484,-0.226 6.155,-0.226c2.283,-0 5.091,0.078 8.437,0.226c3.342,0.156 6.879,0.612 10.6,1.368c3.729,0.764 7.413,1.902 11.058,3.423c3.646,1.52 6.955,3.726 9.917,6.611c2.967,2.888 5.358,6.496 7.183,10.826c1.825,4.331 2.738,9.691 2.738,16.074c-0,10.029 -2.171,18.163 -6.5,24.392c-4.333,6.233 -9.654,11.096 -15.958,14.587c-3.438,1.909 -6.984,3.475 -10.638,4.7c-1.233,0.413 -4.342,1.409 -9.429,2.375c-4.55,0.863 -7.658,1.746 -15.533,1.817c-5.646,-0.196 -7.159,-8.233 -2.28,-8.433Z" fill="none" fillRule="nonzero" stroke="#3a3939" strokeWidth="4.17px"/>
<motion.path variants={pathVar}   d="M886.912,418.103c-0.383,-9.646 -0.8,-19.491 -1.25,-29.52c1.971,0.304 3.871,0.571 5.692,0.797c1.825,0.23 3.65,0.341 5.475,0.341c1.825,-0 3.65,-0.111 5.47,-0.341c1.825,-0.226 3.725,-0.493 5.7,-0.797c-0.454,10.029 -0.875,19.874 -1.254,29.52c-0.379,9.654 -0.571,19.496 -0.571,29.529l0,39.208c0,10.034 0.192,19.913 0.571,29.638c0.379,9.725 0.8,19.304 1.254,28.725c-3.5,-0.304 -7.216,-0.454 -11.17,-0.454c-3.955,-0 -7.675,0.15 -11.167,0.454c0.45,-9.421 0.867,-19 1.25,-28.725c0.379,-9.725 0.567,-19.604 0.567,-29.638l-0,-39.208c-0,-10.033 -0.188,-19.875 -0.567,-29.529Z" fill="none" fillRule="nonzero" stroke="#3a3939" strokeWidth="4.17px"/>
<motion.path variants={pathVar}   d="M1037.5,400.437c-3.958,-0 -8.096,0.041 -12.429,0.115c-4.325,0.078 -8.662,0.23 -12.992,0.456c-4.333,0.23 -8.512,0.497 -12.541,0.797c-4.029,0.308 -7.634,0.686 -10.825,1.142c0.754,-2.736 1.137,-5.165 1.137,-7.297c0,-1.668 -0.383,-4.023 -1.137,-7.067c10.029,0.304 19.833,0.571 29.408,0.797c9.575,0.23 19.379,0.341 29.408,0.341c10.03,-0 19.834,-0.111 29.409,-0.341c9.579,-0.226 19.379,-0.493 29.416,-0.797c-0.77,3.044 -1.15,5.473 -1.15,7.293c0,1.976 0.38,4.335 1.15,7.071c-3.2,-0.456 -6.808,-0.834 -10.833,-1.142c-4.029,-0.3 -8.208,-0.567 -12.542,-0.797c-4.333,-0.226 -8.662,-0.378 -12.995,-0.456c-4.33,-0.074 -8.475,-0.115 -12.425,-0.115c-0.455,15.653 -0.684,31.387 -0.684,47.195l0,39.208c0,10.034 0.192,19.913 0.571,29.638c0.375,9.725 0.8,19.304 1.254,28.725c-3.5,-0.304 -7.221,-0.454 -11.171,-0.454c-3.954,-0 -7.675,0.15 -11.17,0.454c0.458,-9.421 0.87,-19 1.258,-28.725c0.371,-9.725 0.567,-19.604 0.567,-29.638l-0,-39.208c-0,-15.808 -0.225,-31.542 -0.684,-47.195Z" fill="none" fillRule="nonzero" stroke="#3a3939" strokeWidth="4.17px"/>
<motion.path variants={pathVar}   d="M1196.86,474.274c3.954,-0 8.054,0.079 12.308,0.225c4.254,0.158 8.358,0.229 12.313,0.229c4.25,-0 8.508,-0.033 12.766,-0.113c4.254,-0.075 8.513,-0.187 12.767,-0.341l-25.304,-57.909l-24.85,57.909Zm100.996,70.9c-2.134,-0.3 -4.146,-0.454 -6.046,-0.454l-12.079,-0c-1.905,-0 -3.913,0.154 -6.042,0.454c-1.067,-3.492 -2.504,-7.709 -4.333,-12.654c-1.825,-4.93 -3.838,-10.105 -6.038,-15.496c-2.204,-5.396 -4.446,-10.867 -6.729,-16.417c-2.279,-5.546 -4.404,-10.829 -6.379,-15.85c-4.871,-0.146 -9.804,-0.258 -14.817,-0.337c-5.021,-0.075 -10.037,-0.117 -15.05,-0.117c-4.717,-0 -9.35,0.042 -13.908,0.117c-4.559,0.079 -9.196,0.191 -13.909,0.337c-4.862,11.854 -9.27,23.142 -13.225,33.858c-3.954,10.717 -6.916,19.58 -8.887,26.559c-2.588,-0.3 -5.4,-0.454 -8.433,-0.454c-3.046,-0 -5.855,0.154 -8.438,0.454c12.313,-26.592 24.317,-53.034 36.021,-79.338c11.7,-26.291 23.479,-53.039 35.337,-80.244l4.334,0c3.646,8.818 7.596,18.35 11.854,28.61c4.25,10.255 8.583,20.63 12.996,31.122c4.408,10.483 8.812,20.896 13.221,31.229c4.408,10.337 8.545,19.95 12.425,28.842c3.875,8.891 7.37,16.833 10.487,23.829c3.113,6.987 5.654,12.308 7.638,15.95Z" fill="none" fillRule="nonzero" stroke="#3a3939" strokeWidth="4.17px"/>
<motion.path variants={pathVar}   d="M1379.12,418.103c-0.379,-9.646 -0.796,-19.491 -1.25,-29.52c1.975,0.304 3.875,0.571 5.7,0.797c1.825,0.23 3.65,0.341 5.471,0.341c1.829,-0 3.646,-0.111 5.471,-0.341c1.825,-0.226 3.725,-0.493 5.7,-0.797c-0.458,10.029 -0.879,19.874 -1.258,29.52c-0.375,9.654 -0.563,19.496 -0.563,29.529c0,19.146 0.071,35.754 0.221,49.813c0.154,14.058 0.308,26.641 0.462,37.729c10.788,-0 21.117,-0.113 31.005,-0.346c9.879,-0.225 20.52,-1.096 31.912,-2.621c-0.754,2.742 -1.133,5.017 -1.133,6.842c-0,1.675 0.379,3.725 1.133,6.154c-6.987,-0.304 -13.979,-0.454 -20.971,-0.454l-42.066,-0c-6.759,-0 -13.788,0.15 -21.084,0.454c0.454,-9.421 0.871,-19 1.25,-28.725c0.379,-9.725 0.571,-19.604 0.571,-29.638l0,-39.208c0,-10.033 -0.192,-19.875 -0.571,-29.529Z" fill="none" fillRule="nonzero" stroke="#3a3939" strokeWidth="4.17px"/>

</motion.svg>
  )
}
